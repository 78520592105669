import objectPath from "object-path";
import merge from "deepmerge";
import config from "@/core/config/layout.config.json";
import ApiService from "@/core/services/api.service";

// action types

export const BUSINESS_DETAILS = "business/settings/wizard/contact-details";
export const BUSINESS_USERS = "business/settings/users";
export const BUSINESS_MESSAGES = "business/settings/messages";
export const DELETE_MESSAGE = "business/settings/delete-message";
export const BUSINESS_RESERVATIONS = "reservation";
export const BUSINESS_RESERVATIONS_UPDATE = "reservation/update/";
export const BUSINESS_BRANDING_DETAIL = "business/settings/wizard/branding";
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
export const RESET_LAYOUT_CONFIG = "resetLayoutConfig";
export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig";
export const TIMINGS = "business/settings/timings";
export const GET_TIMINGS = "business/settings/get-timings";
export const DELETE_TIMING = "business/settings/delete-timings";
export const DELIVERY_CHARGES = "business/settings/delivery-charges";
export const GET_DELIVERY_CHARGES = "business/settings/get-delivery-charges";
export const DELIVERY_COLLECTION_TIMINGS = "business/settings/delivery-collection-timings";
export const GET_DELIVERY_COLLECTION_TIMINGS = "business/settings/get-delivery-collection-timings";
export const GENERAL_SETTINGS = "business/settings/general-settings";
export const GET_POS_INT_SETTINGS = "business/settings/pos-integration";
export const SEND_TEST_EMAIL = "business/settings/send-test-email";
export const GET_GENERAL_SETTINGS = "business/settings/get-general-settings";
export const ANNOUNCEMENTS = "business/settings/announcements";
export const SAVE_ANNOUNCEMENTS = "business/settings/save-announcements";
export const DELETE_VOUCHER = "business/settings/delete-voucher";



// mutation types

export default {
  state: {
    config: config,
    initial: config
  },
  getters: {
    /**
     * Get config from layout config
     * @param state
     * @returns {function(path, defaultValue): *}
     */
    layoutConfig: state => (path, defaultValue) => {
      return objectPath.get(state.config, path, defaultValue);
    },

    pending(state) {
      return state.config.status.pending;
    },

    acknowledged(state) {
      return state.config.status.acknowledged;
    },

    orderPrepared(state) {
      return state.config.status.prepared;
    },

    orderWay(state) {
      return state.config.status.way;
    },

    cancelled(state) {
      return state.config.status.cancelled;
    },

    delivered(state) {
      return state.config.status.delivered;
    },
    collected(state) {
      return state.config.status.collected;
    },

    delivery(state) {
      return state.config.orderstatus.delivery;
    },
    collection(state) {
      return state.config.orderstatus.collection;
    },

    getDate() {
      var date = new Date();
      return (
        date.getFullYear() +
        "-" +
        ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) +
        "-" +
        ("0" + date.getDate()).slice(-2)
      );
    }
  },
  actions: {
    [ANNOUNCEMENTS](context) {
      return new Promise((resolve, reject) => {
        ApiService.get(ANNOUNCEMENTS)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [DELETE_VOUCHER](context, form) {
      return new Promise((resolve, reject) => {
        ApiService.post(DELETE_VOUCHER, form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [SAVE_ANNOUNCEMENTS](context, form) {
      return new Promise((resolve, reject) => {
        ApiService.post(SAVE_ANNOUNCEMENTS, form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response)
          });
      });
    },

    [BUSINESS_MESSAGES](context, form) {
      return new Promise(resolve => {
        ApiService.post(BUSINESS_MESSAGES, form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [DELETE_MESSAGE](context, form) {
      return new Promise(resolve => {
        ApiService.post(DELETE_MESSAGE, form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [BUSINESS_USERS]({ commit }, { page = 1, perPage = 10 }) {
      console.log('Page:', page, 'Items per page:', perPage);
  
      return new Promise((resolve, reject) => {
        const url = `${BUSINESS_USERS}?page=${page}&per_page=${perPage}`;
  
        ApiService.post(url)
        .then(({ data }) => {
          resolve(data);
        })
          .catch(error => {
            const errors = error.response?.data?.errors || ['An unknown error occurred'];
            commit('SET_ERROR', errors);
            reject(error);
          });
      });
    },
    [GET_TIMINGS](context) {
      return new Promise((resolve, reject) => {
        ApiService.get(GET_TIMINGS)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [TIMINGS](context, form) {
      return new Promise((resolve, reject) => {
        ApiService.post(TIMINGS, form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [DELETE_TIMING](context, form) {
      return new Promise((resolve, reject) => {
        ApiService.post(DELETE_TIMING, form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [GENERAL_SETTINGS](context, form) {
      return new Promise((resolve, reject) => {
        ApiService.post(GENERAL_SETTINGS, form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [SEND_TEST_EMAIL](context, form) {
      return new Promise((resolve, reject) => {
        ApiService.post(SEND_TEST_EMAIL, form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [GET_GENERAL_SETTINGS](context) {
      return new Promise((resolve, reject) => {
        ApiService.get(GET_GENERAL_SETTINGS)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [GET_POS_INT_SETTINGS](context) {
      return new Promise((resolve, reject) => {
        ApiService.get(GET_POS_INT_SETTINGS)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [DELIVERY_CHARGES](context, form) {
      return new Promise((resolve, reject) => {
        ApiService.post(DELIVERY_CHARGES, form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [DELIVERY_COLLECTION_TIMINGS](context, form) {
      return new Promise((resolve, reject) => {
        ApiService.post(DELIVERY_COLLECTION_TIMINGS, form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [GET_DELIVERY_COLLECTION_TIMINGS](context) {
      return new Promise((resolve, reject) => {
        ApiService.get(GET_DELIVERY_COLLECTION_TIMINGS)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [GET_DELIVERY_CHARGES](context) {
      return new Promise((resolve, reject) => {
        ApiService.get(GET_DELIVERY_CHARGES)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [BUSINESS_DETAILS](context) {
      return new Promise((resolve, reject) => {
        ApiService.get(BUSINESS_DETAILS)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response.data)
          });
      });
    },
    [BUSINESS_BRANDING_DETAIL](context) {
      return new Promise(resolve => {
        ApiService.get(BUSINESS_BRANDING_DETAIL)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [BUSINESS_RESERVATIONS](context,{ page = 1, per_page = 10 }) {
      console.log('per_page',per_page)
      return new Promise(resolve => {

        const url = `${BUSINESS_RESERVATIONS}?page=${page}&per_page=${per_page}`;

        ApiService.get(url)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    [BUSINESS_RESERVATIONS_UPDATE](context, form) {
      return new Promise(resolve => {
        ApiService.put(
          BUSINESS_RESERVATIONS_UPDATE + form.id,
          {
              status:form.status
          } 

          )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },
    /**
     * Set and replace the whole config
     * @param state
     * @param payload
     */
    [SET_LAYOUT_CONFIG](state, payload) {
      state.commit(SET_LAYOUT_CONFIG, payload);
    },

    /**
     * Reset the config at the initial state
     * @param state
     */
    [RESET_LAYOUT_CONFIG](state) {
      state.commit(RESET_LAYOUT_CONFIG);
    },

    /**
     * Reset the config using saved config in the cache by the layout builder
     * @param state
     */
    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.commit(OVERRIDE_LAYOUT_CONFIG);
    },

    /**
     * Override config by page level
     * @param state
     * @param payload
     */
    [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
      state.commit(OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
    }
  },
  mutations: {
    [SET_LAYOUT_CONFIG](state, payload) {
      state.config = payload;
    },
    [RESET_LAYOUT_CONFIG](state) {
      state.config = Object.assign({}, state.initial);
    },
    [OVERRIDE_LAYOUT_CONFIG](state) {
      state.config = state.initial = Object.assign(
        {},
        state.initial,
        JSON.parse(localStorage.getItem("config"))
      );
    },
    [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
      state.config = merge(state.config, payload);
    }
  }
};
